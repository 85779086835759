import {api} from "@/app/services/api.service";

const state = {
    practitioner_tasks:null,
    messages:null,
    messages_error:null,
    practitioner_task:null,
    practitioner:null,
    practitionerError:null,
    practitionerComplaint:null,
    practitionerComplaintError:null,
    practitionerComplaints:null,
    practitionerComplaintsError:null,
    insolvency_escalations:null,
    insolvency_escalation:null,
    preview:null,
    task_count:{},
    task_thread:{},
    insolvency_stats:{},
    error:null,
    success:null,
    successAssignment:null,
    insolvencyLoading:false,
    task_update_status_success:null,
};

const getters = {
    getInsolvencyPractitionerSubmissions: (state) => state.practitioner_tasks,
   // getInsolvencyTask: (state) => state.insolvency_task,
    getPractitionerTask: (state) => state.practitioner_task,
    getPractitioner: (state) => state.practitioner,
    getPractitionerError: (state) => state.practitionerError,
    getPractitionerComplaint: (state) => state.practitionerComplaint,
    getPractitionerComplaintError: (state) => state.practitionerComplaintError,
    getPractitionerComplaints: (state) => state.practitionerComplaints,
    getPractitionerComplaintsError: (state) => state.practitionerComplaintsError,
    getPractitionerCount: (state) => state.task_count,
    getPractitionerMessages: (state) => state.messages,
    getPractitionerMessageError: (state) => state.messages_error,
    getInsolvencyAssignmentSuccess:(state) => state.successAssignment,
    getUpdatedPractitionerTaskStatus: (state) => state.task_update_status_success,
    getPractitionerTaskError: (state) => state.error,
    getInsolvencyLoading: (state) => state.insolvencyLoading,
};

const actions = {
    fetchInsolvencyPractitionerTasks({commit}, data){
        api().get("/insolvency/process?status="+data.status+"&page="+data.page+"&pageSize="+data.limit+"&type="+data.type).
        then(response => {
            commit('setPractitionerTasks', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    fetchPractitionersQueueCount({commit},taskType){
        api().get("/insolvency/task/count?type="+taskType).
        then(response => {
            commit('setTaskCount', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    fetchInsolvencyPractitionerTask({commit},type){
        api().get("/insolvency/task/next?type="+type).
        then(response => {
            commit('setNewPractitionerTask', response.data);
        }).catch(error => {
            commit("setPractitionerTaskError", error.response)
        });
    },
    fetchPractitionerTaskMessages({commit}, id){
        api().get("/insolvency/api/insolvency/status?processId="+id).
        then(response => {
            commit('setTaskMessages', response.data);
        }).catch(error => {
            commit("setTaskMessagesError", error.response)
        });
    },
    async fetchPractitionerComplaints({commit}, query) {
        let ext = "?";
        ext += "page="+query.page
        ext += "&perPage="+query.perPage

        if (String(query.status).trim() !== 'null' && String(query.status).trim().length > 0) {
            ext += "&status="+query.status
        }

        if (String(query.practitionerNumber).trim() !== 'null' && String(query.practitionerNumber).trim().length > 0) {
            ext += "&practitionerNumber="+query.practitionerNumber
        }

        await api()
            .get("/insolvency/api/practitioner/complaint"+ext)
            .then(response => {
                commit("setPractitionerComplaints", response);
            })
            .catch(error => {
                commit("setPractitionerComplaintsError", error.response);
            });
    },
    async logPractitionerComplaint({commit}, data) {
        await api()
            .post("/insolvency/api/practitioner/complaint", data)
            .then(response => {
                commit("setPractitionerComplaint", response);
            })
            .catch(error => {
                commit("setPractitionerComplaintError", error.response);
            });
    },
    async approvePractitionerComplaint({commit}, data) {
        await api()
            .post("/insolvency/api/practitioner/complaint/action", data)
            .then(response => {
                commit("setPractitionerComplaint", response);
            })
            .catch(error => {
                commit("setPractitionerComplaintError", error.response);
            });
    },


    updatePractitionerTaskStatus({commit}, data){
        api().post("/insolvency/task/status", data).
        then(response => {
            commit('setUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },

};

const mutations = {

    setPractitionerTasks: (state, response) =>{
        state.practitioner_tasks = response;
    },
    setNewPractitionerTask: (state, response) =>{
        state.practitioner_task = response.data;
    },
    setUpdateStatusSuccess: (state, response) =>{
        state.task_update_status_success = response.data;
    },
    setTaskMessages: (state, response) =>{
        state.messages = response.data;
    },
    setTaskMessagesError: (state, response) =>{
        state.messages_error = response.data;
    },
    setTaskCount: (state, response) =>{
        state.task_count = response;
    },
    setPractitionerComplaint: (state, response) => {
        state.practitionerComplaint = response.data;
    },
    setPractitionerComplaintError: (state, response) => {
        state.practitionerComplaintError = response.data;
    },
    setPractitionerComplaints: (state, response) => {
        state.practitionerComplaints = response.data;
    },
    setPractitionerComplaintsError: (state, response) => {
        state.practitionerComplaintsError = response.data;
    },
    setPractitionerTaskError: (state, response) =>{
        state.error = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
